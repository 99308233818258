<template>
  <div style="padding-left: 1.75rem;">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }}</div>
    </div>
    <template v-if="PERMISIONS.list">
      <table-parent
        :reloadCount="reloadTable"
        :tableHead="tableHead"
        :prepareFn="prepareData"
        :apiModule="tableModule"
        :apiModuleName="tableModuleString"
        @Btn13="viewHistory"
        @totalRecords="(x) => (totalRecords = x)"
      />
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center;">
        <span style="font-size: 4rem;">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/usersPermMixin.js";
import { USERS_ROLES_PAGINATED } from "../api.js";
import { mapGetters, mapMutations } from "vuex";
import TableParent from "@/components/TableParent";
import DialogModal from "@/components/DialogModal";

const posIcon = {
  border: "left",
  tooltip: "Activ",
  css: "font-size:2.2rem;color:green;",
  icon: "fas fa-check-circle",
};
const negIcon = {
  border: "left",
  tooltip: "Dezactivat",
  css: "font-size:2.2rem;color:red;",
  icon: "fas fa-exclamation-circle",
};
const uncIcon = {
  border: "left",
  tooltip: "Neconfirmat",
  css: "font-size:2.2rem;color:#f2da07;",
  icon: "far fa-clock",
};
const statusesMap = {
  active: posIcon,
  disabled: negIcon,
  reset: posIcon,
  unconfirmed: uncIcon,
};

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin],
  components: {
    TableParent,
  },
  data() {
    return {
      reloadTable: 0,
      tableHead: [
        {
          title: "Rol utilizator",
          queryKey: "role",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Data creării",
          queryKey: "createdAt",
          bottomHead: {
            quikFiltre: "date",
            range: true,
            format: "DD.MM.YYYY",
            dateFormat: "YYYY-MM-DDTHH:mm:ssZ"
          },
        },
        {
          title: "Prenume",
          queryKey: "user.first_name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Nume",
          queryKey: "user.last_name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Username",
          queryKey: "user.email",
          bottomHead: {
            quikFiltre: true,
          },
        },

        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "userStatus",
          bottomHead: {
            tooltip: "Status utilizator",
            quikFiltre: "userStatus",
          },
        },
      ],
      startTable: false,
      tableModule: USERS_ROLES_PAGINATED,
      tableModuleString: "USERS_ROLES_PAGINATED",
      totalRecords: 0,
    };
  },
  computed: {
    ...mapGetters(["userRole"]),
    usersFromOrigin() {
      return this.userRole?.role && this.userRole.role !== "superadmin";
    },
    isFromInstitution() {
      return +this.fromInstitution > 0;
    },
    adminMode() {
      return this.usersFromOrigin || this.isFromInstitution;
    },
  },
  watch: {},
  methods: {
    ...mapMutations(["addWindow", "delWindow"]),
    reloadPage() {
      this.reloadTable++;
      this.totalRecords = 0;
    },

    viewHistory(item) {
      if (!Number.isInteger(item?.id)) {
        this.$toastr.e("Anunțați administratorul. #981236");
        return;
      }

      this.addWindow({
        title: "Istoric",
        subtitle: this.readUserObject(item),
        versions: item.history,
        modal: 23,
      });
    },

    prepareData(row) {
      row.id = row.user?.id ?? row.id;

      let role = "";

      let userStatus = row.userRoleStatus;

      let { createdAt } = row;

      const preparation = [
        this.changeRoleView(row.role) || "",
        this.toDateAndTime(row.createdAt),
        row.user.first_name || "-",
        row.user.last_name || "-",
        row.user.email || "-",
        statusesMap[userStatus] || "",
      ];

      //if (this.adminMode) preparation.unshift(this.changeRoleView(role) || "");

      const uuid = row.createdByUser;
      const x = this.PERMISIONS;
      const prepareBtn = [];

      /*       if (checkOwner(x.edit)) {
        prepareBtn.push(2);
      }
      if (userStatus === "disabled" && checkOwner(x.enableUser)) {
        prepareBtn.push([8, "Activează utilizatorul"]);
      } else if (
        ["active", "reset"].includes(userStatus) &&
        x.disableUser === true &&
        checkOwner(x.disableUser)
      ) {
        prepareBtn.push([9, "Dezactivează utilizatorul"]);
      }
      if (checkOwner(x.delete)) {
        prepareBtn.push(3);
      }
      if (userStatus == "unconfirmed") {
        prepareBtn.push(3);
      } */
      //prepareBtn.push(13);
      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }
      return preparation;
    },
  },

  async created() {
    if (!this.PERMISIONS.list) {
      return;
    }
    if (this.checkParamsInObj(this.PERMISIONS, ["edit"])) {
      /*     this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      }); */
    }
  },
  mounted() {
    this.startTable = true;
  },
};
</script>
